export const Images = {
  logo: require('../Asset/images/Logo.png'),
  report: {
    cover: require('../Asset/Report/cover.jpg'),
    cover1: require('../Asset/Report/cover1.jpg'),
    cover2: require('../Asset/Report/cover2.jpg'),
    cover3: require('../Asset/Report/cover3.jpg'),
    cover4: require('../Asset/Report/cover4.jpg'),
    cover5: require('../Asset/Report/cover5.jpg'),
    "page3-1": require('../Asset/Report/page3-1.png'),
  },
  knowledge: {
    topic1: require('../Asset/Knowledge/topic1.png'),
    topic2: require('../Asset/Knowledge/topic2.png'),
    topic3: require('../Asset/Knowledge/topic3.png'),
    topic4: require('../Asset/Knowledge/topic4.png'),
  }
};