import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "react-router-redux";
import { autoRehydrate } from "redux-persist";
import rootReducer from "../Reducers";
// import rootSaga from "../Sagas";
// import RehydrationServices from "../Services/RehydrationServices";
// import ReduxPersist from "../Config/ReduxPersist";
// const Reactotron = require('reactotron-react-js').default
// import { createEpicMiddleware } from "redux-observable";
// import { rootEpic } from "../chat/rootRxEpic";
import thunk from "redux-thunk";
const createBrowserHistory = require("history").createBrowserHistory;

export const history = createBrowserHistory();
// const epicMiddleware = createEpicMiddleware(rootEpic);
const enhancers = [autoRehydrate()];
const middleware = [
  routerMiddleware(history), thunk, 
  // epicMiddleware
];
// const sagaMonitor = process.env.NODE_ENV !== 'production' ? Reactotron.createSagaMonitor() : null
const sagaMonitor = process.env.NODE_ENV !== "production" ? null : null;
export const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
middleware.push(sagaMiddleware);

if (process.env.NODE_ENV === `development`) {
  //   const { logger } = require(`redux-logger`);
  //   middlewares.push(logger);

  // <!-- for devtools.
  const { composeWithDevTools } = require("redux-devtools-extension");
  const withDev = composeWithDevTools(applyMiddleware(...middleware));
  enhancers.push(withDev);
} else {
  console.log = () => null;
  enhancers.push(applyMiddleware(...middleware));
}

// const createAppropriateStore = process.env.NODE_ENV !== 'production' ? Reactotron.createStore : createStore
const createAppropriateStore = process.env.NODE_ENV !== "production"
  ? createStore
  : createStore;
const store = createAppropriateStore(rootReducer, compose(...enhancers));

// if (ReduxPersist.active) {
//   RehydrationServices.updateReducers(store)
// }

// sagaMiddleware.run(rootSaga);

export default store;
