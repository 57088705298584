import { validateRegister, eachValidateRegister, pairValidateRegister } from './register';
import validateIdCard from './idCard';
import { email, password } from './fields';
import validateRoute from './route';
import { updatePassword } from './updatePassword';
import { manageRole } from './manageRole';

export default {
    validateRegister, eachValidateRegister, validateRoute,
    pairValidateRegister, validateIdCard, email, password,
    updatePassword, manageRole,
}