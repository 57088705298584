// export const ROLE = {
//     ADMIN1: 'admin1',
//     ADMIN2: 'admin2',
//     ADMIN3: 'admin3',
//     MEMBER: 'member',
//     GUEST: 'guest',
// }

export const ROLE = {
    SUPER_ADMIN: 'super_admin',
    ADMIN1: 'admin1',
    ADMIN2: 'admin2',
    ADMIN3: 'admin3',
    ADMIN4: 'admin4',
    EXPERT: 'expert',
    USER: 'user',
    GUEST: 'guest',
}

export const ALL_ROLE = Object.values(ROLE);
export const ALL_ADMIN = Object.values(ROLE).filter(each => each.includes('admin'));