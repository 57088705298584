import apisauce from 'apisauce'
import Reactotron from 'reactotron-react-js'
import { path, replace } from 'ramda';
import Config from '../Config/Api'
import store, { history } from '../Store';
import { CLEAR_STORE } from '../Sagas/ManageStoreSaga';

export const isResponseSuccess = (res) => !!path(['data', 'isStatus'], res);

const getPathString = (path) => replace(/\?|\.|\//g, '', path);

const withToken = (headers = {}) => ({
    headers: {
        token: path(['AuthenReducer', 'user', 'token'], store.getState()),
        ...headers,
    },
});

const create = (baseURL = `${Config.api.host}`) => {
    const api = apisauce.create({
        baseURL,
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Cache-Control': 'no-cache',
        // },
        // 2mins timeout
        timeout: 1000 * 60 * 2
    });

    api.addResponseTransform(res => {
        // if false any api kickout
        // TODO set in reducer for avoiding refresh page
        if (!path(['data', 'isStatus'], res)) {
            const ignore = [
                // auth
                Config.api.login, Config.api.login_guest, Config.api.login_social, Config.api.loginToken,
                // register, update-profile
                Config.api.register, Config.api.updateProfile,
                // forgot password
                Config.api.forgotPassword, Config.api.changePassword,
                // universal api
                Config.api.getLinkList, Config.api.getAllComplaintStatus,
            ].map(getPathString)
            const apiUrl = getPathString(res.config.url);
            if (!ignore.find(each => new RegExp(each + '$').test(apiUrl))) {
                store.dispatch({ type: CLEAR_STORE });
                if (history.location.pathname !== '/') {
                    history.push(`/`);
                }
                return;
            }
        }
    });

    if (process.env.NODE_ENV !== 'production') {
        api.addMonitor(Reactotron.apisauce)
    }

    const authen = (payload) => api.post(`${Config.api.login}`, payload)
    const authenWithSocial = (payload) => api.post(`${Config.api.login_social}`, payload)
    const authenToken = (payload) => api.post(`${Config.api.loginToken}`, payload)
    const guestLogin = () => api.post(`${Config.api.login_guest}`)
    const register = (payload) => api.post(`${Config.api.register}`, payload)
    const updateUserProfile = (payload) => api.post(`${Config.api.updateProfile}`, payload, withToken())
    const updateBackAccount = (payload) => api.post(`${Config.api.updateAccount}`, payload, withToken())
    const logout = () => api.post(`${Config.api.logout}`, {}, withToken())
    const forgotPassword = (payload) => api.post(`${Config.api.forgotPassword}`, payload)
    const changePassword = (payload) => api.post(`${Config.api.changePassword}`, { password: payload.password }, { headers: { token: payload.token } })
    const updatePassword = (payload) => api.post(`${Config.api.updatePassword}`, payload.body, withToken())
    const updateSetting = (payload) => api.post(`${Config.api.updateSetting}`, payload.body, withToken())
    // manage role
    const createRole = (payload) => api.post(`${Config.api.createRole}`, payload.body, withToken())
    const getRole = (payload) => api.post(`${Config.api.getRole}`, payload.body, withToken())
    const updateRole = (payload) => api.post(`${Config.api.updateRole}`, payload.body, withToken())
    const deleteRole = (payload) => api.post(`${Config.api.deleteRole}`, payload.body, withToken())

    const getAdminGroup = () => api.post(`${Config.api.getAdminGroup}`, {}, withToken())

    const getProcess = (payload) => api.post(`${Config.api.getProcess}`, payload.body, withToken())

    //Feed and Knowledge API ================================================================================================================================
    const feed = (payload) => api.post(`${Config.api.feed}`, payload.body, withToken())
    const getKnowledgeList = (payload) => api.post(`${Config.api.knowledgeList}`, payload.body, withToken())
    const getKnowledgeDetail = (payload) => api.post(`${Config.api.knowledgeDetail}`, payload)
    const createKnowledge = (payload) => api.post(`${Config.api.createKnowledge}`, payload.body, withToken())
    const createKnowledgeDetail = (payload) => api.post(`${Config.api.createKnowledgeDetail}`, payload.body, withToken())
    const deleteKnowledgeDetail = (payload) => api.post(`${Config.api.deleteKnowledgeDetail}`, payload.body, withToken())
    const updateKnowledge = (payload) => api.post(`${Config.api.updateKnowledge}`, payload.body, withToken())
    const updateKnowledgeDetail = (payload) => api.post(`${Config.api.updateKnowledgeDetail}`, payload.body, withToken())
    const upLoadDocForKnowLegeDetail = (payload) => api.post(`${Config.api.upLoadDocForKnowLegeDetail}`, payload.data, withToken())
    //=======================================================================================================================================================
    const getBankList = () => api.get(`${Config.api.bankList}`)
    const getComplaintSocialList = () => api.get(`${Config.api.socialMediaList}`)
    const getComplaintBusinessTypeList = () => api.get(`${Config.api.businessTypeList}`)
    const getComplaintPrintingList = () => api.get(`${Config.api.printingMediaList}`)
    const getUserList = (payload) => api.post(`${Config.api.userList}`, payload.body, withToken())
    const getAdminChatList = () => api.get(`${Config.api.adminChatList}`, {}, withToken())
    const exportUsers = (payload) => api.post(`${Config.api.exportUsers}`, payload.data, withToken())
    const getRoleList = (payload) => api.post(`${Config.api.roleList}`, payload.body, withToken())
    const getDepartmentList = (payload) => api.post(`${Config.api.getDepartmentList}`, payload.body, withToken())
    // const createComplaint = () => api.get(`${Config.api.createComplaint}`, {}, withToken())
    const getComplaint = (payload) => api.get(`${Config.api.getComplaint}`, {}, withToken({ id: payload.id }))
    const updateUser = (payload) => api.post(`${Config.api.updateUser}`, payload.body, withToken())
    const saveComplaint = (payload) => api.post(`${Config.api.saveComplaint}`, payload.body, withToken())
    const saveDraftComplaint = (payload) => api.post(`${Config.api.saveDraftComplaint}`, payload.body, withToken())
    const getDraftComplaint = () => api.get(`${Config.api.getDraftComplaint}`, {}, withToken())
    const getComplaintTypeList = () => api.get(`${Config.api.complaintTypeList}`, {}, withToken())
    const createComplaintType = (payload) => api.post(`${Config.api.createComplaintType}`, payload.body, withToken())
    const updateComplaintType = (payload) => api.post(`${Config.api.updateComplaintType}`, payload.body, withToken())
    const getComplainantType = () => api.get(`${Config.api.getComplainantType}`)
    const deleteComplaintType = (payload) => api.post(`${Config.api.deleteComplaintType}`, payload.body, withToken())
    const saveOrderComplaintType = (payload) => api.post(`${Config.api.saveOrderComplaintType}`, payload.body, withToken())
    const getComplaintSelect = (payload) => api.post(`${Config.api.getComplaintsSelect}`, payload.data, withToken())
    const getComplaintTag = () => api.get(`${Config.api.getComplaintTag}`, {}, withToken())
    const getComplaintStatusList = () => api.get(`${Config.api.getComplaintStatusList}`, {}, withToken())
    const getComplaintAdmin = (payload) => api.post(`${Config.api.getComplaintAdmin}`, payload.data, withToken())
    const saveComplaintAdmin = (payload) => api.post(`${Config.api.saveComplaintAdmin}`, payload.data, withToken())
    const getCommentComplaint = (payload) => api.post(`${Config.api.getCommentComplaint}`, payload.data, withToken())
    const postCommentComplaint = (payload) => api.post(`${Config.api.postCommentComplaint}`, payload.data, withToken())
    // Pending API
    const removeComplaint = (payload) => api.post(`${Config.api.removeComplaint}`, payload.data, withToken())
    const exportComplaint = (payload) => api.post(`${Config.api.exportComplaint}`, payload.data, withToken())
    const exportComplaintHtml = (payload) => api.post(`${Config.api.exportComplaintHtml}`, payload.data, withToken())
    // ============
    const getDashboardStatistic = () => api.get(`${Config.api.getDashboardStatistic}`, {}, withToken())
    const getDashboardComplaint = () => api.get(`${Config.api.getDashboardComplaint}`, {}, withToken())
    const getDashboardUpdateComplaint = () => api.get(`${Config.api.getDashboardUpdateComplaint}`, {}, withToken())
    const getDashboardReport = () => api.get(`${Config.api.getDashboardReport}`, {}, withToken())
    const getDashboardArea = () => api.get(`${Config.api.getDashboardArea}`, {}, withToken())
    const getDashboardLaws = () => api.get(`${Config.api.getDashboardLaws}`, {}, withToken())
    const deleteUser = (payload) => api.post(`${Config.api.deleteUser}`, payload.body, withToken())
    const getAllComplaintStatus = () => api.get(`${Config.api.getAllComplaintStatus}`)
    // Law API
    const getLawList = () => api.get(`${Config.api.getLawList}`, {}, withToken())
    const createLaw = (payload) => api.post(`${Config.api.createLaw}`, payload.data, withToken())
    const updateLaw = (payload) => api.post(`${Config.api.updateLaw}`, payload.data, withToken())
    const deleteLaw = (payload) => api.post(`${Config.api.deleteLaw}`, payload.data, withToken())
    const saveOrderLaw = (payload) => api.post(`${Config.api.saveOrderLaw}`, payload.body, withToken())

    // Report API
    const getReportLaw = (payload) => api.post(`${Config.api.getReportLaw}`, payload.data, withToken())
    const getReportRegionComplaint = (payload) => api.post(`${Config.api.getReportRegionComplaint}`, payload.data, withToken())
    const getReportComplaintStatus = (payload) => api.post(`${Config.api.getReportComplaintStatus}`, payload.data, withToken())
    const getReportByRole = (payload) => api.post(`${Config.api.getReportByRole}`, payload.body, withToken())
    const getReportInvestigate = (payload) => api.post(`${Config.api.getReportInvestigate}`, payload.body, { headers: { token: payload.token }, timeout: 30000 })

    // Links
    const getLinkList = () => api.get(`${Config.api.getLinkList}`);
    const updateLink = (payload) => api.post(`${Config.api.updateLink}`, payload.body, withToken())
    return {
        authen,
        authenWithSocial,
        authenToken,
        guestLogin,
        register,
        updateUserProfile,
        logout,
        forgotPassword,
        changePassword,
        updatePassword,
        updateSetting,
        feed,
        getKnowledgeList,
        getKnowledgeDetail,
        createKnowledge,
        createKnowledgeDetail,
        deleteKnowledgeDetail,
        updateKnowledge,
        updateKnowledgeDetail,
        upLoadDocForKnowLegeDetail,
        updateBackAccount,
        getBankList,
        getComplaintSocialList,
        getComplaintBusinessTypeList,
        getComplaintPrintingList,
        getUserList,
        exportUsers,
        getRoleList,
        getDepartmentList,
        // createComplaint,
        getComplaint,
        updateUser,
        saveComplaint,
        saveDraftComplaint,
        getDraftComplaint,
        getComplaintTypeList,
        createComplaintType,
        updateComplaintType,
        deleteComplaintType,
        saveOrderComplaintType,
        getComplaintStatusList,
        saveComplaintAdmin,
        getCommentComplaint,
        postCommentComplaint,
        getComplainantType,
        getComplaintSelect,
        getComplaintTag,
        removeComplaint,
        exportComplaint,
        exportComplaintHtml,
        getDashboardStatistic,
        getDashboardComplaint,
        getDashboardUpdateComplaint,
        getDashboardReport,
        getDashboardArea,
        getDashboardLaws,
        deleteUser,
        getAllComplaintStatus,
        getLawList,
        createLaw,
        updateLaw,
        deleteLaw,
        saveOrderLaw,
        getComplaintAdmin,
        getAdminChatList,

        getReportLaw,
        getReportRegionComplaint,
        getReportComplaintStatus,
        getReportByRole,
        getReportInvestigate,

        createRole,
        getRole,
        updateRole,
        deleteRole,
        getAdminGroup,
        getProcess,

        getLinkList,
        updateLink,
    }
}

export default {
    create
}