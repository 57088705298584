import { 
    GET_COMPLAINT_LIST_REQUEST, GET_COMPLAINT_LIST_SUCCESS, GET_COMPLAINT_LIST_FAILURE,
    GET_ALL_COMPLAINT_STATUS_REQUEST, GET_ALL_COMPLAINT_STATUS_SUCCESS, GET_ALL_COMPLAINT_STATUS_FAILURE,
} from '../Sagas/ComplaintSaga'

/* ------------- Initial State ------------- */

export const initialState = {
    dropDownSocial: null,
    dropDownBusiness: null,
    dropDownPrinting: null,
    complainantType: null,
    complaintGroup: null,
    complaintStatus: null,
    isFetching: false,
    error: null
}

/* ------------- Reducers ------------- */

export function ComplaintReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_COMPLAINT_STATUS_REQUEST:
            return Object.assign({}, state, { isFetching: true, error: false })

        case GET_ALL_COMPLAINT_STATUS_SUCCESS:
            return Object.assign({}, state, { complaintStatus: action.payload, isFetching: false, error: false })

        case GET_ALL_COMPLAINT_STATUS_FAILURE:
            return Object.assign({}, state, { isFetching: false, error: true })

        case GET_COMPLAINT_LIST_REQUEST:
            return Object.assign({}, state, { isFetching: true, error: false })

        case GET_COMPLAINT_LIST_SUCCESS:
            return Object.assign({}, state, {
                dropDownSocial: action.payload.social,
                dropDownBusiness: action.payload.business,
                dropDownPrinting: action.payload.printing,
                complainantType: action.payload.complainantType,
                complaintGroup: action.payload.complaintGroup,
                isFetching: false, error: false
            })

        case GET_COMPLAINT_LIST_FAILURE:
            return Object.assign({}, state, { isFetching: false, error: true })

        default:
            return state
    }
}