import { without, clone } from 'ramda';
import { ROLE, ALL_ROLE, ALL_ADMIN } from '../Const/Role';
import { PathName } from '../Const/PathName';

export const ADMIN_ACTION = {
    CONFIRM_COMPLAINT: {
        ACTION: 'CONFIRM_COMPLAINT',
        ROLE: [ROLE.SUPER_ADMIN, ROLE.ADMIN1, ROLE.ADMIN2]
    },
    COMMENT_COMPLAINT: {
        ACTION: 'COMMENT_COMPLAINT',
        ROLE: [...ALL_ADMIN, ROLE.EXPERT]
    },
    RECEIVE_REWARD: {
        ACTION: 'RECEIVE_REWARD',
        ROLE: without([ROLE.GUEST], ALL_ROLE)
    },
    MANAGE_MISTAKE_CREATE: {
        ACTION: 'MANAGE_MISTAKE_CREATE',
        ROLE: [ROLE.SUPER_ADMIN, ROLE.ADMIN1]
    },
    MANAGE_MISTAKE_UPDATE: {
        ACTION: 'MANAGE_MISTAKE_UPDATE',
        ROLE: [ROLE.SUPER_ADMIN, ROLE.ADMIN1]
    },
    MANAGE_MISTAKE_DELETE: {
        ACTION: 'MANAGE_MISTAKE_DELETE',
        ROLE: [ROLE.SUPER_ADMIN]
    },
    EXPORT_COMPLAINT_HTML: {
        ACTION: 'EXPORT_COMPLAINT_HTML',
        ROLE: [ROLE.SUPER_ADMIN, ROLE.ADMIN1, ROLE.ADMIN2]
    }
}

export const ROUTE_ACTIONS = {
    FEED: 'FEED',
    COMPLAINT: 'COMPLAINT',
    NEWCOMPLAINT: 'NEWCOMPLAINT',
    DASHBOARD: 'DASHBOARD',
    USER: 'USER',
    USER_PROFILE: 'USER_PROFILE',
    USER_ACCOUNT: 'USER_ACCOUNT',
    CHAT: 'CHAT',
    SUPPORT: 'SUPPORT',
    KNOWLEDGE: 'KNOWLEDGE',
    KNOWLEDGE_DETAIL: 'KNOWLEDGE_DETAIL',
    MANAGE: 'MANAGE',
    MANAGE_COMPLAINT: 'MANAGE_COMPLAINT',
    MANAGE_COMPLAINTTYPE: 'MANAGE_COMPLAINTTYPE',
    MANAGE_USERS: 'MANAGE_USERS',
    MANAGE_KNOWLEDGE: 'MANAGE_KNOWLEDGE',
    MANAGE_MISTAKE: 'MANAGE_MISTAKE',
    MANAGE_ROLE: 'MANAGE_ROLE',
    MANAGE_LINK: 'MANAGE_LINK',
    REPORT: 'MANAGE_REPORT',
    VIEWUSERPROFILE: 'VIEWUSERPROFILE',
    CREATEUSER: 'CREATEUSER',
    UPDATEUSER: 'UPDATEUSER',
};

export const ROUTE_ACTION_DETAIL = [
    {
        ACTION: ROUTE_ACTIONS.FEED,
        PATH: PathName.FEED,
        ROLE: ALL_ROLE,
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 1,
    },
    {
        ACTION: ROUTE_ACTIONS.COMPLAINT,
        PATH: `${PathName.COMPLAINT}/:id`,
        ROLE: ALL_ROLE,
        ISSIDEBAR: false,
        ISROUTER: true,
    },
    {
        ACTION: ROUTE_ACTIONS.NEWCOMPLAINT,
        PATH: PathName.NEWCOMPLAINT,
        ROLE: ALL_ROLE,
        ISSIDEBAR: false,
        ISROUTER: true,
    },
    {
        ACTION: ROUTE_ACTIONS.DASHBOARD,
        PATH: PathName.DASHBOARD,
        ROLE: ALL_ADMIN,
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 2,
    },
    {
        ACTION: ROUTE_ACTIONS.USER,
        ROLE: without([ROLE.GUEST], ALL_ROLE),
        ISSIDEBAR: true,
        ISROUTER: false,
        ORDER: 3,
    },
    {
        ACTION: ROUTE_ACTIONS.USER_PROFILE,
        PATH: PathName.USERPROFILE,
        ROLE: without([ROLE.GUEST], ALL_ROLE),
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 1,
    },
    {
        ACTION: ROUTE_ACTIONS.USER_ACCOUNT,
        PATH: PathName.USERACCOUNT,
        ROLE: without([ROLE.GUEST], ALL_ROLE),
        ISSIDEBAR: false,
        ISROUTER: true,
        ORDER: 2,
    },
    {
        ACTION: ROUTE_ACTIONS.CHAT,
        PATH: PathName.CHAT,
        ROLE: without([ROLE.GUEST, ROLE.USER], ALL_ROLE),
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 4,
    },
    {
        ACTION: ROUTE_ACTIONS.SUPPORT,
        PATH: PathName.SUPPORT,
        ROLE: ALL_ROLE,
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 4,
    },
    {
        ACTION: ROUTE_ACTIONS.KNOWLEDGE_DETAIL,
        PATH: `${PathName.KNOWLEDGEDETAIL}/:id`,
        ROLE: ALL_ROLE,
        ISSIDEBAR: false,
        ISROUTER: true,
    },
    {
        ACTION: ROUTE_ACTIONS.KNOWLEDGE,
        PATH: PathName.KNOWLEDGE,
        ROLE: ALL_ROLE,
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 5,
    },
    {
        ACTION: ROUTE_ACTIONS.MANAGE,
        ROLE: [ROLE.SUPER_ADMIN, ROLE.ADMIN1, ROLE.ADMIN2],
        ISSIDEBAR: true,
        ISROUTER: false,
        ORDER: 6,
    },
    {
        ACTION: ROUTE_ACTIONS.MANAGE_COMPLAINT,
        PATH: PathName.MANAGECOMPLAINT,
        ROLE: [ROLE.SUPER_ADMIN],
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 1,
    },
    {
        ACTION: ROUTE_ACTIONS.MANAGE_COMPLAINTTYPE,
        PATH: PathName.MANAGECOMPLAINTTYPE,
        ROLE: [ROLE.SUPER_ADMIN, ROLE.ADMIN1],
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 2,
    },
    {
        ACTION: ROUTE_ACTIONS.MANAGE_USERS,
        PATH: PathName.MANAGEUSERS,
        ROLE: [ROLE.SUPER_ADMIN],
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 3,
    },
    {
        ACTION: ROUTE_ACTIONS.CREATEUSER,
        PATH: PathName.CREATEUSER,
        ROLE: [ROLE.SUPER_ADMIN],
        ISROUTER: true,
    },
    {
        ACTION: ROUTE_ACTIONS.UPDATEUSER,
        PATH: PathName.UPDATEUSER,
        ROLE: [ROLE.SUPER_ADMIN],
        ISROUTER: true,
    },
    {
        ACTION: ROUTE_ACTIONS.MANAGE_KNOWLEDGE,
        PATH: PathName.MANAGEKNOWLEDGE,
        ROLE: [ROLE.SUPER_ADMIN],
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 4,
    },
    {
        ACTION: ROUTE_ACTIONS.MANAGE_MISTAKE,
        PATH: PathName.MANAGEMISTAKE,
        ROLE: [ROLE.SUPER_ADMIN, ROLE.ADMIN1],
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 5,
    },
    {
        ACTION: ROUTE_ACTIONS.MANAGE_ROLE,
        PATH: PathName.MANAGEROLE,
        ROLE: [ROLE.SUPER_ADMIN],
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 6,
    },
    {
        ACTION: ROUTE_ACTIONS.REPORT,
        PATH: PathName.REPORT,
        ROLE: [ROLE.SUPER_ADMIN, ROLE.ADMIN1, ROLE.ADMIN2],
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 7,
    },
    {
        ACTION: ROUTE_ACTIONS.MANAGE_LINK,
        PATH: PathName.MANAGELINK,
        ROLE: [ROLE.SUPER_ADMIN],
        ISSIDEBAR: true,
        ISROUTER: true,
        ORDER: 8,
    },
    {
        ACTION: ROUTE_ACTIONS.VIEWUSERPROFILE,
        PATH: PathName.VIEWUSERPROFILE,
        ROLE: [ROLE.SUPER_ADMIN],
        ISSIDEBAR: false,
        ISROUTER: true,
    },
];

export const getAccessRoute = (role = '', sidebar = false) => {
    let route = [];
    if (role !== '') {
        route = ROUTE_ACTION_DETAIL.filter(each => each.ROLE.indexOf(role) > -1);
    }
    else {
        route = clone(ROUTE_ACTION_DETAIL);
    }
    if (sidebar) {
        let parent = route.filter(each => each.ISSIDEBAR && each.ACTION.indexOf('_') < 0).sort((a, b) => a.ORDER - b.ORDER).slice();
        let children = route.filter(each => each.ISSIDEBAR && each.ACTION.indexOf('_') > -1).sort((a, b) => a.ORDER - b.ORDER);
        let parent_ref = {};
        let access_route = clone(parent);
        children.map(each => {
            let parent_name = each.ACTION.split('_')[0];
            if (!parent_ref[parent_name]) {
                let parent_index = access_route.findIndex(each => each.ACTION === parent_name);
                parent_ref[parent_name] = parent_index;
                access_route[parent_ref[parent_name]].CHILDREN = [];
            }
            access_route[parent_ref[parent_name]].CHILDREN.push(each);
            return null;
        });
        return access_route;
    }
    else {
        return route.filter(each => each.ISROUTER && !!each.PATH);
    }
}

export function isAccessSection(role, action) {
    let require
    switch (action) {
        case ADMIN_ACTION[action].ACTION:
            require = ADMIN_ACTION[action].ROLE
            return canAccess(require, role)
        // case ADMIN_ACTION.COMMENT_COMPLAINT.ACTION:
        //     require = ADMIN_ACTION.COMMENT_COMPLAINT.ROLE
        //     return canAccess(require, role)
        // case ADMIN_ACTION.RECEIVE_REWARD.ACTION:
        //     require = ADMIN_ACTION.RECEIVE_REWARD.ROLE
        //     return canAccess(require, role)
        // case ADMIN_ACTION.MANAGE_MISTAKE_CREATE.ACTION:
        //     require = ADMIN_ACTION.MANAGE_MISTAKE_CREATE.ROLE
        //     return canAccess(require, role)
        // case ADMIN_ACTION.MANAGE_MISTAKE_UPDATE.ACTION:
        //     require = ADMIN_ACTION.MANAGE_MISTAKE_UPDATE.ROLE
        //     return canAccess(require, role)
        // case ADMIN_ACTION.MANAGE_MISTAKE_DELETE.ACTION:
        //     require = ADMIN_ACTION.MANAGE_MISTAKE_DELETE.ROLE
        //     return canAccess(require, role)
        default:
            return false
    }
}

function canAccess(requireRole, role) {
    if (requireRole.indexOf(role) > -1) return true
    else return false
}