import validate from 'validate.js';

export const manageRole = (obj) => {
  const constraints = {
    name: {
      presence: {
        message: "^ชื่อสิทธิ์การเข้าถึงต้องไม่เป็นค่าว่าง",
        allowEmpty: false,
      },
    },
    description: {
      presence: {
        message: "^คำอธิบายต้องไม่เป็นค่าว่าง",
        allowEmpty: false,
      },
    },
    admin_group: {
      length: {
        minimum: 1,
        tooShort: "^ต้องเลือกอย่างน้อย %{count} ตัวเลือก",
      }
    }
  };
  return validate(obj, constraints);
}