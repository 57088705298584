import { AUTH_USER_REQUEST, AUTH_USER_SUCCESS, AUTH_USER_FAILURE, AUTH_TOKEN_REQUEST, AUTH_TOKEN_SUCCESS, AUTH_TOKEN_FAILURE } from '../Sagas/AuthenSaga'
import ReactGA from "react-ga";
import { path } from 'ramda';

/* ------------- Initial State ------------- */
const user = { id: null, email: null, token: null, role_code: null }
export const initialState = {
    user: { ...user },
    isFetching: false,
    error: null
}

/* ------------- Reducers ------------- */

export function AuthenReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_USER_REQUEST:
            return Object.assign({}, state, { isFetching: true, error: false })

        case AUTH_TOKEN_SUCCESS:
        case AUTH_USER_SUCCESS:
            const userId = path(['payload', 'user', 'id'], action);
            ReactGA.set({ userId });
            return Object.assign({}, state, { user: action.payload.user, isFetching: false, error: false })

        case AUTH_USER_FAILURE:
            return Object.assign({}, state, { isFetching: false, error: true })

        case AUTH_TOKEN_REQUEST:
            return Object.assign({}, state, { isFetching: true, error: false })

        case AUTH_TOKEN_FAILURE:
            return Object.assign({}, state, { user: { ...user }, isFetching: false, error: true })

        default:
            return state
    }
}