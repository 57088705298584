import { path } from "ramda";
import { put, call } from "redux-saga/effects";
import { api } from "../Sagas";
export const GET_COMPLAINT_LIST_REQUEST = "GET_COMPLAINT_LIST_REQUEST";
export const GET_COMPLAINT_LIST_SUCCESS = "GET_COMPLAINT_LIST_SUCCESS";
export const GET_COMPLAINT_LIST_FAILURE = "GET_COMPLAINT_LIST_FAILURE";

export const GET_ALL_COMPLAINT_STATUS_REQUEST = "GET_ALL_COMPLAINT_STATUS_REQUEST";
export const GET_ALL_COMPLAINT_STATUS_SUCCESS = "GET_ALL_COMPLAINT_STATUS_SUCCESS";
export const GET_ALL_COMPLAINT_STATUS_FAILURE = "GET_ALL_COMPLAINT_STATUS_FAILURE";

export function getComplaintListRequest() {
  return {
    type: GET_COMPLAINT_LIST_REQUEST
  }
}

export function getComplaintBrandTag() {
  return new Promise((resolve, reject) => {
    api.getComplaintTag().then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function getComplaintStatus() {
  return new Promise((resolve, reject) => {
    api.getComplaintStatusList().then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function getComplaintAdmin(id) {
  return new Promise((resolve, reject) => {
    let payload = {
      data: {
        id
      },
    }
    api.getComplaintAdmin(payload).then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function saveComplaintAdmin(payload) {
  return new Promise((resolve, reject) => {
    api.saveComplaintAdmin(payload).then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function* getComplaintList(api) {
  const res_social = yield call(api.getComplaintSocialList);
  const res_business = yield call(api.getComplaintBusinessTypeList);
  const res_printing = yield call(api.getComplaintPrintingList);
  const res_complainantType = yield call(api.getComplainantType);
  const res_complaint_group = yield call(api.getAdminGroup);
  try {
    if (res_social.status === 200 && res_business.status === 200 && res_printing.status === 200 && res_complainantType.status === 200 && res_complaint_group.status === 200 &&
      res_social.data.isStatus && res_business.data.isStatus && res_printing.data.isStatus && res_complainantType.data.isStatus && res_complaint_group.data.isStatus) {
      const social = path(["data", "data", "social_media"], res_social);
      const business = path(["data", "data", "business_type"], res_business);
      const printing = path(["data", "data", "printing_media"], res_printing);
      const complainantType = path(["data", "data"], res_complainantType);
      const complaintGroup = path(["data", "data"], res_complaint_group);
      if (social[0].name === "") social[0].name = "โปรดระบุประเภท"
      if (printing[0].name === "") printing[0].name = "โปรดระบุประเภท"
      // complaintGroup.unshift({ id: 0, name: "โปรดระบุประเภทเรื่องร้องเรียน" })
      yield put({ type: GET_COMPLAINT_LIST_SUCCESS, payload: { social: social, business: business, printing: printing, complainantType: complainantType, complaintGroup } });
    } else {
      yield put({ type: GET_COMPLAINT_LIST_FAILURE });
    }

  } catch (e) {
    yield put({ type: GET_COMPLAINT_LIST_FAILURE });
  }
}

export function saveComplaint(payload) {
  return new Promise((resolve, reject) => {
    api.saveComplaint(payload).then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function getDraftComplaint() {
  return new Promise((resolve, reject) => {
    api.getDraftComplaint().then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function saveDraftComplaint(payload) {
  return new Promise((resolve, reject) => {
    api.saveDraftComplaint(payload).then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function getComplaint(id) {
  return new Promise((resolve, reject) => {
    let payload = { id };
    api.getComplaint(payload).then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function getCommentComplaint(id) {
  return new Promise((resolve, reject) => {
    let payload = { data: { id } };
    api.getCommentComplaint(payload).then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function postCommentComplaint(payload) {
  return new Promise((resolve, reject) => {
    api.postCommentComplaint(payload).then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject(res.problem);
      }
    });
  });
}

export function getComplaintSelect(payload, mockupResponseCallback) {
  // let default_response = {
  //   isStatus: true,
  //   page_size: 10,
  //   page_index: 0,
  //   record_count: 0,
  //   data:[]
  // }
  let default_request = {
    select_field: [],
    filter_field: [],
    order: [{ field: "code", order: "acs" }],
    page_size: 10,
    page_index: 0
  }
  return new Promise((resolve, reject) => {
    let build_payload = {
      data: { ...default_request, ...payload }
    };
    console.log('\nData: ', build_payload.data);
    api.getComplaintSelect(build_payload).then(res => {
      if (res.status === 200) {
        resolve(res);
      } else {
        console.error("Request error: ", res);
        reject(res.problem);
      }
    }, error => {
      console.error("Request error: ", error);
      reject(error)
    });
  });
}

//# Pending API
export function removeComplaint(payload) {
  return new Promise((resolve, reject) => {
    let build_payload = {
      data: { ...payload }
    };
    console.log("Post token: ", build_payload.token, '\nData: ', build_payload.data);
    api.removeComplaint(build_payload).then(res => {
      if (res.status === 200) {
        resolve(res);
      } else {
        reject(res.problem);
      }
    }, error => reject(error));
  });
}

export function exportComplaint(payload) {
  return new Promise((resolve, reject) => {
    let build_payload = {
      data: { ...payload }
    };
    console.log("exportComplaint.Post : ", build_payload);
    api.exportComplaint(build_payload).then(res => {
      if (res.status === 200) {
        resolve(res);
      } else {
        reject(res.problem);
      }
    }, error => reject(error));
  });
}

export function exportComplaintHtml(payload) {
  return new Promise((resolve, reject) => {
    let build_payload = {
      data: { ...payload }
    };
    console.log("exportComplaint.Post : ", build_payload);
    api.exportComplaintHtml(build_payload).then(res => {
      if (res.status === 200) {
        resolve(res);
      } else {
        reject(res.problem);
      }
    }, error => reject(error));
  });
}

export function* getAllComplaintStatus(api) {
  try {
    const res = yield call(api.getAllComplaintStatus);
    if (res.status === 200 && res.data.isStatus) {
      yield put({
        type: GET_ALL_COMPLAINT_STATUS_SUCCESS,
        payload: res.data.data
      });
    } else {
      yield put({ type: GET_ALL_COMPLAINT_STATUS_FAILURE });
    }
  } catch (e) {
    yield put({ type: GET_ALL_COMPLAINT_STATUS_FAILURE });
  }
}
//# ============