const validate = require("validate.js");

export function email(email, callback) {
    let constraints = {
        email: {
            // email: true,
            // presence: true
            email: {
                message: "^อีเมลไม่ถูกต้อง"
            },
            presence: {
                message: "^อีเมลต้องไม่เป็นค่าว่าง"
            }
        }
    };
    callback(validate({
        email: email,
    }, constraints));
}

export function password(password, confirm_password, callback) {
    let constraints = {
        password: {
            presence: true,
            equality: "confirm_password",
            length: {
                minimum: 8,
                message: "must be at least 8 characters"
            }
        },
    };
    callback(validate({
        password: password,
        confirm_password: confirm_password,
    }, constraints));
}