import { combineReducers } from "redux";
import { StartupReducer, initialState as initialStartupState } from "./StartupReducer";
import { AuthenReducer, initialState as initialAuthenState } from "./AuthenReducer";
import { UserReducer, initialState as initialUserState } from "./UserReducer";
import { ComplaintReducer, initialState as initialComplaintState } from './ComplaintReducer'
import { LinkReducer, initialState as initialLinkState } from './LinkReducer';
import { CLEAR_STORE } from '../Sagas/ManageStoreSaga';

// <!-- chat -->

// import { reducer as notificationsReducer } from 'reapop';
// import { chatroomReducer, chatRoomRecoder } from "../chat/chitchat/chats/redux/chatroom/chatroomReducer";
// import { chatlogReducer, chatlogInitRecord } from "../chat/chitchat/chats/redux/chatlogs/chatlogReducer";
// import { stalkReducer, StalkInitState } from "../chat/chitchat/chats/redux/stalkBridge/stalkReducer";
// import * as StalkBridgeActions from "../chat/chitchat/chats/redux/stalkBridge/stalkBridgeActions";

// import { Logout } from '../Sagas/AuthenSaga';

// Combines all reducers to a single reducer function

const appReducer = combineReducers({
  // StartupReducer: StartupReducer,
  // AuthenReducer: AuthenReducer,
  // UserReducer: UserReducer,
  // chatroomReducer: chatroomReducer
  StartupReducer,
  AuthenReducer,
  UserReducer,
  // chatroomReducer,
  // chatlogReducer,
  // stalkReducer,
  ComplaintReducer,
  LinkReducer,
  // notifications: notificationsReducer()
});

const initRootReducer = {
  StartupReducer: initialStartupState,
  AuthenReducer: initialAuthenState,
  UserReducer: initialUserState,
  ComplaintReducer: initialComplaintState,
  LinkReducer: initialLinkState,
  // chatroomReducer: chatRoomRecoder,
  // chatlogReducer: chatlogInitRecord,
  // stalkReducer: new StalkInitState(),
}

const rootReducer = (state, action) => {
  console.log("ReducerActions: ", action);
  if (action.type === CLEAR_STORE) {
    state = {
      ...initRootReducer,
      LinkReducer: state.LinkReducer,
      ComplaintReducer: {
        ...initialComplaintState,
        complaintStatus: state.ComplaintReducer.complaintStatus,
      },
    };
  }
  // else if (action.type === StalkBridgeActions.STALK_ON_SOCKET_CLOSE) {
  //   Logout().then(() => {
  //     window.location = '/';
  //   }).catch(() => {
  //     window.location = '/';
  //   });
  // }
  return appReducer(state, action);
}

export default rootReducer;
