import { all, takeLatest } from 'redux-saga/effects'
import { startup, STARTUP } from './StartupSaga';
import { authenUserToken, AUTH_TOKEN_REQUEST } from './AuthenSaga'
import { 
    getComplaintList, GET_COMPLAINT_LIST_REQUEST,
    getAllComplaintStatus, GET_ALL_COMPLAINT_STATUS_REQUEST,
} from './ComplaintSaga'
import { getLinkList, GET_LINKS_REQUEST, updateLink, UPDATE_LINK_REQUEST } from './LinkSaga';
import API from '../Services/Api'

const api = API.create();
export { api }

export default function* root() {
    yield all([
        takeLatest(STARTUP, startup),
        takeLatest(AUTH_TOKEN_REQUEST, authenUserToken, api),
        takeLatest(GET_COMPLAINT_LIST_REQUEST, getComplaintList, api),
        takeLatest(GET_ALL_COMPLAINT_STATUS_REQUEST, getAllComplaintStatus, api),
        takeLatest(GET_LINKS_REQUEST, getLinkList, api),
        takeLatest(UPDATE_LINK_REQUEST, updateLink, api),
    ])
}

