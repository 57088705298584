// http://www.thaicreate.com/community/thai-id-card.html

export default function validateIdCard(id_card) {
    let i = 0, sum = 0;
    for (i; i < 12; i++) {
        sum += parseFloat(id_card.charAt(i)) * (13 - i);
    }
    if ((11 - sum % 11) % 10 !== parseFloat(id_card.charAt(12))) {
        return false;
    }
    return true;
}