const PathName = {
    HOME: '/',
    REGISTER: '/register',
    FORGOT: '/forgot',
    CHANGEPASSWORD: '/changepassword',
    FEED: '/feed',
    COMPLAINT: '/complaint',
    NEWCOMPLAINT: '/newcomplaint',
    USERPROFILE: '/userprofile',
    USERACCOUNT: '/useraccount',
    SUPPORT: '/support',
    CHAT: '/chat',
    KNOWLEDGE: '/knowledge',
    KNOWLEDGEDETAIL: '/knowledgedetail',
    DASHBOARD: '/dashboard',
    MANAGECOMPLAINT: '/managecomplaint',
    MANAGECOMPLAINTTYPE: '/managecomplainttype',
    MANAGEUSERS: '/manageusers',
    MANAGEKNOWLEDGE: '/manageknowledge',
    MANAGEMISTAKE: '/managemistake',
    MANAGEROLE: '/managerole',
    MANAGELINK: '/managelink',
    REPORT: '/report',
    NOTFOUND: '/notfound',
    VIEWUSERPROFILE: '/viewuserprofile',
    CREATEUSER: '/createuser',
    UPDATEUSER: '/updateuser',
}

export { PathName }