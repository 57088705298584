import validate from 'validate.js';

export const updatePassword = (obj) => {
    const constraints = {
        oldPassword: {
            presence: {
                message: "^รหัสผ่านเก่าต้องไม่เป็นค่าว่าง"
            },
            length: {
                minimum: 8,
                message: "^รหัสผ่านเก่าต้องมีอักขระอย่างน้อย 8 ตัว"
            }
        },
        newPassword: {
            presence: {
                message: "^รหัสผ่านใหม่ต้องไม่เป็นค่าว่าง"
            },
            equality: {
                attribute: "confirmPassword",
                message: "^ยืนยันรหัสผ่านไม่ตรงกัน",
            },
            length: {
                minimum: 8,
                message: "^รหัสผ่านใหม่ต้องมีอักขระอย่างน้อย 8 ตัว"
            }
        },
        confirmPassword: {
            presence: {
                message: "^ยืนยันรหัสผ่านต้องไม่เป็นค่าว่าง"
            },
            equality: {
                attribute: "newPassword",
                message: "^ยืนยันรหัสผ่านไม่ตรงกัน"
            }
        },
    };
    return validate(obj, constraints);
}