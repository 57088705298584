import React from "react";
import { Provider } from "react-redux";
import { Router, Route } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import RehydrationServices from './Services/RehydrationServices';
import ReduxPersist from './Config/ReduxPersist';
import Store, { history, sagaMiddleware } from "./Store";
import rootSaga from "./Sagas";
import "./App.css";
import { getAccessRoute } from './Helper/AccessAdmin';
import Validator from './Helper/Validator';
import withTracker from './Helper/withTracker';

import Fallback from "./component/Fallback";
import { PathName } from "./Const/PathName";
import { Colors } from './Theme';

// import { ReapopNotiBoxWithState } from "./chat/containers/NotificationSystem";
// import { chitchatFactory, config } from "./chat/Chitchat";
const Home = React.lazy(() => import("./containers/Home"));
const Main = React.lazy(() => import("./containers/Main"));
const NoMatch = React.lazy(() => import("./component/NoMatch"));
global["userAgent"] = "react-js";
// chitchatFactory.initConfig(config);
// chitchatFactory.initStore(Store);
// chitchatFactory.initSecureService();
// Store.subscribe(() => {
//     chitchatFactory.setAuthStore(
//         Store.getState().AuthenReducer.user
//     );
// });

export async function asyncLoadStore(callback) {
    sagaMiddleware.run(rootSaga);
    if (ReduxPersist.active) {
        await RehydrationServices.updateReducers(Store);
    }
    callback();
}

const theme = createMuiTheme({
    overrides: {
        MuiDialogContentText: { root: { fontSize: 22, } },
        MuiButton: { label: { fontSize: 20, lineHeight: 1.25 } },
        MuiTypography: { body1: { fontSize: 22 }, body2: { fontSize: 22 }, h3: { fontWeight: 700 } },
        MuiTab: { root: { backgroundColor: Colors.theme } },
        MuiInputBase: { root: { fontSize: 20 } },
        MuiFormLabel: { root: { fontSize: 22 } },
        MuiMenuItem: { root: { fontSize: 22 } },
        MuiFormHelperText: { root: { fontSize: 18 } },
        MuiSvgIcon: { root: { fontSize: 24 } },
    },
    typography: {
        fontFamily: "THSarabunNew",
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: Colors.theme,
        }
    }
});

const App = () => {
    return (
        <Provider store={Store}>
            <React.Fragment>
                {/* <ReapopNotiBoxWithState /> */}
                <MuiThemeProvider theme={theme}>
                    <Router history={history}>
                        <React.Fragment>
                            <Route exact path={`${PathName.HOME}`} component={WaitingComponent(Home)} />
                            <Route path={`${PathName.REGISTER}`} component={WaitingComponent(Home)} />
                            <Route path={`${PathName.FORGOT}`} component={WaitingComponent(Home)} />
                            <Route path={`${PathName.CHANGEPASSWORD}`} component={WaitingComponent(Home)} />
                            {
                                getAccessRoute().map((each, index) => {
                                    return <Route path={each.PATH} component={WaitingComponent(Main)} key={index} />
                                })
                            }
                            <Route render={renderNoMatch} />
                        </React.Fragment>
                    </Router>
                </MuiThemeProvider>
            </React.Fragment>
        </Provider>
    );
}

const WaitingComponent = (Component) => {
    Component = withTracker(Component);
    return props => (
        <React.Suspense fallback={<Fallback />}>
            <Component {...props} />
        </React.Suspense>
    );
}

const renderNoMatch = (props) => {
    if (!Validator.validateRoute(Object.keys(PathName).map(key => PathName[key]), props.location.pathname)) {
        return <NoMatch {...props} />;
    }
    return null;
}

export default App;
