import { isInject } from '../Helper/deviceHelper';
export * from './Colors';
export * from './Images';
export default {
    footer: {
        height: isInject() ? '0px' : '30px',
    },
    header: {
        /*
        desktop: {
            height: "64px",
        },
        mobile: {

        }
        */
        height: isInject() ? '0px' : '53px',
    }
}