import idCard from './idCard'
const regisLabel = require('../../Store/Localization/regis.json');
const validate = require("validate.js");

validate.validators.idCard = function(value) {
    if (idCard(value)) {
        return undefined;
    }
    return "^" + regisLabel.invalid_id_card_number;
};

const constraints = {
    email: {
        email: {
            message: "^อีเมลไม่ถูกต้อง"
        },
        presence: {
            message: "^อีเมลต้องไม่เป็นค่าว่าง"
        }
    },
    password: {
        presence: {
            message: "^รหัสผ่านต้องไม่เป็นค่าว่าง",
            allowEmpty: false,
        },
        equality: {
            attribute: "confirm_password",
            message: "^ยืนยันรหัสผ่านไม่ตรงกัน"
        },
        length: {
            minimum: 8,
            message: "^รหัสผ่านต้องมีอักขระอย่างน้อย 8 ตัว"
        }
    },
    confirm_password: {
        presence: {
            message: "^ยืนยันรหัสผ่านต้องไม่เป็นค่าว่าง",
            allowEmpty: false,
        },
        equality: {
            attribute: "password",
            message: "^ยืนยันรหัสผ่านไม่ตรงกัน"
        }
    },
    first_name: {
        presence: {
            message: "^ชื่อต้องไม่เป็นค่าว่าง",
            allowEmpty: false,
        },
    },
    last_name: {
        presence: {
            message: "^นามสกุลต้องไม่เป็นค่าว่าง",
            allowEmpty: false,
        },
    },
    id_card: {
        presence: {
            message: "^รหัสบัตรประชาชนต้องไม่เป็นค่าว่าง"
        },
        format: {
            pattern: "[0-9]+",
            message: "^รหัสบัตรประชาชนต้องเป็นตัวเลข"
        },
        idCard: true,
    },
    address: {
        presence: {
            message: "^ที่อยู่ต้องไม่เป็นค่าว่าง"
        },
    },
    sub_district_code: {
        numericality: {
            notValid: "^ตำบลต้องไม่เป็นค่าว่าง",
            notInteger: "^ตำบลต้องไม่เป็นค่าว่าง",
        },
    },
    district_code: {
        numericality: {
            notValid: "^อำเภอต้องไม่เป็นค่าว่าง",
            notInteger: "^อำเภอต้องไม่เป็นค่าว่าง",
        },
    },
    province_code: {
        numericality: {
            notValid: "^จังหวัดต้องไม่เป็นค่าว่าง",
            notInteger: "^จังหวัดต้องไม่เป็นค่าว่าง",
        },
    },
    zipcode: {
        presence: {
            message: "^รหัสไปรษณีย์ต้องไม่เป็นค่าว่าง",
            allowEmpty: false,
        },
        numericality: {
            notValid: "^รหัสไปรษณีย์ต้องไม่เป็นค่าว่าง",
            notInteger: "^รหัสไปรษณีย์ต้องไม่เป็นค่าว่าง",
        },
    },
    telephone: {
        presence: {
            message: "^เบอร์โทรศัพท์ต้องไม่เป็นค่าว่าง"
        },
        format: {
            pattern: "^0[0-9]+",
            message: "^เบอร์โทรศัพท์จะต้องเป็นตัวเลข และเริ่มต้นด้วย 0 เท่านั้น"
        },
        length: {
            minimum: 9,
            maximum: 10,
            tooShort: "^เบอร์โทรศัพท์ต้องมีอย่างน้อย 9 ตัว",
            tooLong: "^เบอร์โทรศัพท์ต้องมีไม่เกิน 10 ตัว",
        }
    },
};

export function validateRegister(data, register_type, callback) {
    let thisConstraints = { ...constraints }
    if (data.pwdCheck !== undefined || register_type === 'social') {
        delete thisConstraints.password;
        delete thisConstraints.confirm_password;
    }
    callback(validate({
        email: data.email,
        password: data.password,
        confirm_password: data.confirm_password,
        first_name: data.first_name,
        last_name: data.last_name,
        id_card: data.id_card,
        address: data.address,
        sub_district_code: data.sub_district_code,
        district_code: data.district_code,
        province_code: data.province_code,
        zipcode: data.zipcode,
        telephone: data.telephone,
    }, thisConstraints));
}

export function eachValidateRegister(fieldName, data) {
    let selectField = {}
    let select_constraints = {}
    selectField[fieldName] = data;
    select_constraints[fieldName] = constraints[fieldName];
    let result = validate(selectField, select_constraints);
    return result;
}

export function pairValidateRegister(keyValuePairs) {
    //console.warn('pairValidateRegister', keyValuePairs);
    let select_constraints = {}
    let values_builder = {}
    for (let ele of keyValuePairs) {
        select_constraints[ele.field] = constraints[ele.field];
        //console.warn(ele.value, typeof ele.value);
        values_builder[ele.field] = ele.value;
    }
    return validate(values_builder, select_constraints);
}