export default function validateRoute(routes, pathname) {
    let rootPathname = pathname;
    if (pathname.lastIndexOf('/') > 0) {
        rootPathname = pathname.substring(0, pathname.slice(1).indexOf('/') + 1);
    }
    if (routes.some(each => each.indexOf(rootPathname) > -1)) {
        // if (routes.some(each => { console.warn(each, rootPathname); return each === rootPathname })) {
        return true;
    }
    return false;
}