import { api } from '../Sagas';
export const CLEAR_STORE = 'CLEAR_STORE'

export function GetBankList(){
    return new Promise((resolve, reject)=>{
        api.getBankList().then(res=>{
            console.log("GetBankList: ",res);
            resolve(res);
        }, error=>{
            console.error("GetBankList: ",error);
            reject(error);
        });
    });
}