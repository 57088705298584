import { path } from 'ramda';
import Store from "../Store";
import { api } from '../Sagas';

export const UPDATE_USER_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_SETTING_SUCCESS = 'UPDATE_USER_SETTING_SUCCESS';
export const SAVE_SOCIAL_DATA = 'SAVE_SOCIAL_DATA';
export const CLEAR_SOCIAL_DATA = 'CLEAR_SOCIAL_DATA';

export function UpdateUserProfile(payload) {
    return new Promise((resolve, reject) => {
        console.log("UpdateUserProfile with payload: ", payload);
        api.updateUserProfile(payload).then(res => {
            console.log("UpdateUserProfile res: ", res);
            if (res.data.isStatus) {
                resolve(res);
                if (!payload.user_id) {
                    Store.dispatch({ type: UPDATE_USER_PROFILE, payload: res.data.data })
                }
            } else {
                reject(res);
            }
        }, err => {
            console.warn(err);
        });
    });
}

export function forgotPassword(email) {
    return new Promise((resolve, reject) => {
        api.forgotPassword({ email }).then(res => {
            //console.log("forgotPassword res: ", res);
            if (res.status === 200 && !!res.data && res.data.isStatus) {
                resolve({ success: true, message: res.data.message });
            }
            else {
                resolve({ success: false, message: res.data.message });
            }
        }, err => {
            resolve({ success: false, message: "Fail, please try again later." });
        });
    });
}

export function chagePassword(password, token) {
    return new Promise((resolve, reject) => {
        api.changePassword({ password, token }).then(res => {
            // console.log("chagePassword res: ", res);
            if (res.status === 200 && !!res.data && res.data.isStatus) {
                resolve({ success: true, message: res.data.message });
            }
            else {
                resolve({ success: false, message: res.data.message });
            }
        }, err => {
            resolve({ success: false, message: "Fail, please try again later." });
        });
    });
}

export function UpdateAccount(payload) {
    return new Promise((resolve, reject) => {
        console.log("UpdateAccount with payload: ", payload);
        api.updateBackAccount(payload).then(res => {
            Store.dispatch({ type: UPDATE_USER_BANK_ACCOUNT, payload: res.data.data })
            //console.log("UpdateAccount res: ", res);
            resolve(res);
        }, err => {
            console.error(err);
        });
    });
}

export function updatePassword(body) {
    return new Promise((resolve, reject) => {
        api.updatePassword({ body }).then(res => {
            // console.log("chagePassword res: ", res);
            if (res.status === 200 && !!res.data && res.data.isStatus) {
                resolve({ success: true, message: res.data.message });
            }
            else {
                resolve({ success: false, message: res.data.message });
            }
        }, err => {
            resolve({ success: false, message: "Fail, please try again later." });
        });
    });
}

export function updateSetting(body) {
    return new Promise((resolve, reject) => {
        api.updateSetting({ body }).then(res => {
            console.log("updateSetting res: ", res);
            if (res.status === 200 && !!res.data && res.data.isStatus) {
                Store.dispatch({ type: UPDATE_USER_SETTING_SUCCESS, payload: res.data.data })
                resolve({ success: true, message: res.data.message });
            }
            else {
                resolve({ success: false, message: path(['data', 'message'], res) });
            }
        }, err => {
            resolve({ success: false, message: "Fail, please try again later." });
        });
    });
}