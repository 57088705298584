import { put } from 'redux-saga/effects'
import { path } from 'ramda';
import { AUTH_TOKEN_REQUEST } from './AuthenSaga'
import { GET_LINKS_REQUEST } from './LinkSaga';
import { GET_ALL_COMPLAINT_STATUS_REQUEST } from './ComplaintSaga';
import { PathName } from '../Const/PathName';
import Store, { history } from '../Store'
import { isInject } from '../Helper/deviceHelper';

export const STARTUP = 'STARTUP'

export function* startup(action) {
    console.warn(`----------${STARTUP}----------`)

    const { AuthenReducer } = Store.getState();
    const { pathname, search } = window.location;
    const token = search !== '' && pathname !== PathName.CHANGEPASSWORD ?
        search.replace('?', '') : path(['user', 'token'], AuthenReducer);
    // register().then((res) => console.warn('1', res));

    if (token) {
        yield put({ type: AUTH_TOKEN_REQUEST, payload: { token, isMobile: isInject() } })
    } else if (pathname !== '/') {
        const ignoreRedirect = [PathName.CHANGEPASSWORD, PathName.FORGOT, PathName.REGISTER];
        if (ignoreRedirect.indexOf(pathname) > -1) {
            return;
        }
        history.push(`/`);
    }

    yield put({ type: GET_LINKS_REQUEST });
    yield put({ type: GET_ALL_COMPLAINT_STATUS_REQUEST });
    // TEST
    // yield put({ type: 'AUTH_USER_REQUEST', payload: { email: 'test1@test.com', password: '12345678' } })
    // yield put({ type: 'REGISTER_REQUEST', payload: RegisterMockModel })
}

export function StartupAction() {
    return {
        type: STARTUP,
    }
}
