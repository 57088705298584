// import "./Config/ReactotronConfig";
import React from "react";
import ReactDOM from "react-dom";
import App, { asyncLoadStore } from "./App";
//import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import "./style/main.scss";

asyncLoadStore(() => ReactDOM.render(<App />, document.getElementById("root")));
// ReactDOM.render(<App />, document.getElementById("root"));
//registerServiceWorker();
