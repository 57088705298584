import { AUTH_USER_SUCCESS, AUTH_TOKEN_SUCCESS } from '../Sagas/AuthenSaga'
import { SAVE_SOCIAL_DATA, CLEAR_SOCIAL_DATA, UPDATE_USER_PROFILE, UPDATE_USER_BANK_ACCOUNT,
    UPDATE_USER_SETTING_SUCCESS
} from '../Sagas/UserSaga';
/* ------------- Initial State ------------- */

export const initialState = {
    profile: {
        id: null,
        user_id: null,
        avatar: null,
        first_name: null,
        last_name: null,
        id_card: null,
        id_card_image: null,
        address: null,
        soi: null,
        road: null,
        district_code: null,
        sub_district_code: null,
        province_code: null,
        zipcode: null,
        mobile: null,
        telephone: null
    },
    account: {
        account_name: '',
        account_no: '',
        bank_branch: '',
        bank_id: null,
        book_image: null,
        id: null,
        user_id: null
    },
    social: {
        email: '',
        first_name: '',
        last_name: '',
        avatar: null,
        social_type: null,
        fb_id: null,
        google_id: null,
    },
    setting: null,
    isFetching: false,
    error: null
}

/* ------------- Reducers ------------- */

export function UserReducer(state = initialState, action) {
    switch (action.type) {
        // case AUTH_USER_REQUEST:
        //     return Object.assign({}, state, { isFetching: true, error: false })

        case AUTH_USER_SUCCESS:
        case AUTH_TOKEN_SUCCESS:
            return Object.assign({}, state,
                {
                    profile: action.payload.user_profile,
                    account: action.payload.user_bank_account,
                    setting: action.payload.user_setting,
                    isFetching: false,
                    error: false
                })
        case UPDATE_USER_PROFILE:
            return Object.assign({}, state,
                {
                    profile: action.payload.user_profile,
                    isFetching: false,
                    error: false
                })
        case UPDATE_USER_SETTING_SUCCESS:
            return Object.assign({}, state,
                {
                    setting: action.payload.user_setting,
                    isFetching: false,
                    error: false
                })
        case UPDATE_USER_BANK_ACCOUNT:
            console.log("UPDATE_USER_BANK_ACCOUNT: ",action.payload);
            return Object.assign({},state,
                {
                    account: action.payload.user_bank_account,
                    isFetching: false,
                    error: false
                })
        // case AUTH_USER_FAILURE:
        //     return Object.assign({}, state, { isFetching: false, error: true })

        // case AUTH_TOKEN_REQUEST:
        //     return Object.assign({}, state, { isFetching: true, error: false })

        // case AUTH_TOKEN_SUCCESS:
        //     return Object.assign({}, state, { user: action.payload.user, isFetching: false, error: false })

        // case AUTH_TOKEN_FAILURE:
        //     return Object.assign({}, state, { isFetching: false, error: true })
        case SAVE_SOCIAL_DATA:
            return Object.assign({}, state, { social: action.payload })
        case CLEAR_SOCIAL_DATA:
            return Object.assign({}, state, { social: initialState.social })
        default:
            return state
    }
}