export const appVersion = `2.0.14`;
console.warn(`=============[ Build V ${appVersion} ]=============`)
const isDevMode = false;

const Develop_API = `https://tas.go.th`;
const Develop_Host = `https://tas.go.th`;

const Production_API = `/`;
const Production_Host = `/`;

const hostConf = {
    hostURL: isDevMode ? Develop_Host : Production_Host,
    apiURL: isDevMode ? Develop_API : Production_API
}

export const URL_UPLOAD = "https://tas.go.th"

const Config = {
    api: {
        host: `${hostConf.apiURL}`,
        fileUploadHost: `https://chitchats.ga`,
        fileUpload: `https://chitchats.ga/chats/upload`,
        login: `/web/index.php?r=users/login`,
        login_social: `/web/index.php?r=users/login-with-social`,
        login_guest: `/web/index.php?r=users/login-guest`,
        loginToken: `/web/index.php?r=users/login-with-token`,
        register: `/web/index.php?r=users/register`,
        updateProfile: '/web/index.php?r=users/update-profile',
        uploadUserAvatar: '/web/index.php?r=users/upload-avatar',
        uploadUserIdCard: '/web/index.php?r=users/upload-id-card-image',
        uploadComplaint: '/web/index.php?r=complaint/upload-complaint',
        uploadChatFile: '/web/index.php?r=chat/upload-file-chat',
        logout: `/web/index.php?r=users/logout`,
        forgotPassword: `/web/index.php?r=users/forgot-password`,
        changePassword: `/web/index.php?r=users/change-password`,
        updatePassword: `/web/index.php?r=users/update-password`,
        updateSetting: `/web/index.php?r=users/update-setting`,

        feed: `/web/index.php?r=complaint/feed`,
        knowledgeList: `/web/index.php?r=knowledge/knowledge-list`,
        knowledgeDetail: `/web/index.php?r=knowledge/knowledge-detail`,

        createKnowledge: `/web/index.php?r=knowledge/create-knowledge`,
        createKnowledgeDetail: `/web/index.php?r=knowledge/create-knowledge-detail`,
        updateKnowledge: `/web/index.php?r=knowledge/update-knowledge`,
        updateKnowledgeDetail: `/web/index.php?r=knowledge/update-knowledge-detail`,
        upLoadDocForKnowLegeDetail: `/web/index.php?r=knowledge/upload-knowledge-doc`,
        deleteKnowledge: `/web/index.php?r=knowledge/delete-knowledge`,
        deleteKnowledgeDetail: `/web/index.php?r=knowledge/delete-knowledge-detail`,

        updateAccount: `/web/index.php?r=user-bank-account/account`,
        uploadBookBank: '/web/index.php?r=user-bank-account/upload-book-bank',
        bankList: '/web/index.php?r=user-bank-account/bank-list',

        adminList: "/web/index.php?r=users/admin-list",

        businessTypeList: '/web/index.php?r=business-type/business-type-list',
        printingMediaList: '/web/index.php?r=printing-media/printing-media-list',
        socialMediaList: '/web/index.php?r=social-media/social-media-list',

        userList: '/web/index.php?r=users/user-list',
        adminChatList: '/web/index.php?r=users/user-chat-list',
        updateUser: '/web/index.php?r=users/update-user',
        deleteUser: '/web/index.php?r=users/delete-user',
        exportUsers: '/web/index.php?r=users/export-users',
        // role controller
        roleList: '/web/index.php?r=role/role-list',
        // crud role
        createRole: '/web/index.php?r=role/create-role',
        getRole: '/web/index.php?r=role/get-role',
        updateRole: '/web/index.php?r=role/update-role',
        deleteRole: '/web/index.php?r=role/delete-role',

        // admin group controller
        getAdminGroup: '/web/index.php?r=admin-group/admin-group-list',

        // process controller
        getProcess: '/web/index.php?r=process/process-list',

        complaintTypeList: '/web/index.php?r=complaint-type/complaint-type-list',
        createComplaintType: '/web/index.php?r=complaint-type/create-complaint-type',
        updateComplaintType: '/web/index.php?r=complaint-type/update-complaint-type',
        deleteComplaintType: '/web/index.php?r=complaint-type/delete-complaint-type',
        saveOrderComplaintType: '/web/index.php?r=complaint-type/save-order',

        getAllComplaintStatus: '/web/index.php?r=complaint/all-complaint-status',
        saveComplaint: '/web/index.php?r=complaint/save-complaint',
        // createComplaint: '/web/index.php?r=complaint/create-complaint',
        getComplaint: '/web/index.php?r=complaint/complaint-detail',
        saveDraftComplaint: '/web/index.php?r=complaint/save-draft',
        getDraftComplaint: 'web/index.php?r=complaint/complaint-detail-draft',
        getComplainantType: '/web/index.php?r=channel/feedname',
        getComplaintsSelect: '/web/index.php?r=complaint-admin/feed-admin',
        getComplaintTag: '/web/index.php?r=product/feedname',
        getComplaintStatusList: '/web/index.php?r=complaint/feed-status',
        getComplaintAdmin: '/web/index.php?r=complaint-admin/feed-complaint',
        saveComplaintAdmin: '/web/index.php?r=complaint-admin/update-admin',
        getCommentComplaint: '/web/index.php?r=complaint-admin/feed-comment',
        postCommentComplaint: '/web/index.php?r=complaint-admin/create-comment',
        getDepartmentList: '/web/index.php?r=department/department-list',
        // Pending API
        removeComplaint: '/web/index.php?r=complaint/delete-complaint',
        exportComplaint: '/web/index.php?r=complaint/export-complaint',
        exportComplaintHtml: '/web/index.php?r=complaint/export-complaint-to-html',
        // ============

        getDashboardStatistic: 'web/index.php?r=dashboard/statistics-month-year',
        getDashboardComplaint: 'web/index.php?r=dashboard/complaint',
        getDashboardUpdateComplaint: 'web/index.php?r=dashboard/update-status',
        getDashboardReport: 'web/index.php?r=dashboard/report',
        getDashboardArea: 'web/index.php?r=dashboard/province-area',
        getDashboardLaws: 'web/index.php?r=dashboard/laws',

        //Law API
        getLawList: '/web/index.php?r=law-section/feedname',
        createLaw: '/web/index.php?r=law-section/create-law-section',
        updateLaw: '/web/index.php?r=law-section/update-law-section',
        deleteLaw: '/web/index.php?r=law-section/delete-law-section',
        saveOrderLaw: '/web/index.php?r=law-section/save-order',

        // Report API
        getReportLaw: '/web/index.php?r=report/report-law',
        getReportRegionComplaint: '/web/index.php?r=report/report-region-complaint',
        getReportComplaintStatus: '/web/index.php?r=report/report-complaint-status',
        getReportByRole: '/web/index.php?r=report/report-by-role',
        getReportInvestigate: '/web/index.php?r=report/report-investigate',

        // manual
        userManual: `/uploads/tas2_user_manual.pdf`,

        // links
        getLinkList: '/web/index.php?r=link/link-list',
        updateLink: '/web/index.php?r=link/update-link',
    }
}

export default Config;

// http://alchohol.animation-genius.com:8080/web/index.php?r=users/admin-list
// export const staff_icon = `http://alchohol.animation-genius.com:8080/uploads/image/icon-app/admin_icon.png`;
export const staff_icon = `${hostConf.hostURL}/uploads/image/icon-app/admin_icon.png`;