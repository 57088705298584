// import { path } from "ramda";
import { put, call } from "redux-saga/effects";

export const GET_LINKS_REQUEST = "GET_LINKS_REQUEST";
export const GET_LINKS_SUCCESS = "GET_LINKS_SUCCESS";
export const GET_LINKS_FAILURE = "GET_LINKS_FAILURE";

export const UPDATE_LINK_REQUEST = "UPDATE_LINK_REQUEST";
export const UPDATE_LINK_SUCCESS = "UPDATE_LINK_SUCCESS";
export const UPDATE_LINK_FAILURE = "UPDATE_LINK_FAILURE";

export function* getLinkList(api) {
  try {
    const res = yield call(api.getLinkList);
    if (res.status === 200 && res.data.isStatus) {
      yield put({
        type: GET_LINKS_SUCCESS,
        payload: res.data.data
      });
    } else {
      yield put({ type: GET_LINKS_FAILURE });
    }
  } catch (e) {
    yield put({ type: GET_LINKS_FAILURE });
  }
}

export function updateLinkRequest(body) {
  return {
    type: UPDATE_LINK_REQUEST,
    payload: {
      body,
    }
  }
}

export function* updateLink(api, action) {
  try {
    const res = yield call(api.updateLink, action.payload);
    if (res.status === 200 && res.data.isStatus) {
      yield put({
        type: UPDATE_LINK_SUCCESS,
        payload: res.data.data
      });
    } else {
      yield put({ type: UPDATE_LINK_FAILURE });
    }
  } catch (e) {
    yield put({ type: UPDATE_LINK_FAILURE });
  }
}