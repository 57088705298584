import { 
  GET_LINKS_REQUEST, GET_LINKS_SUCCESS, GET_LINKS_FAILURE, 
  UPDATE_LINK_REQUEST, UPDATE_LINK_SUCCESS, UPDATE_LINK_FAILURE, 
} from '../Sagas/LinkSaga'

/* ------------- Initial State ------------- */
export const initialState = {
  link: [],
  isFetching: false,
  error: null
}

/* ------------- Reducers ------------- */

export function LinkReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LINKS_REQUEST:
      return Object.assign({}, state, { isFetching: true, error: false })

    case GET_LINKS_SUCCESS:
      return Object.assign({}, state, { link: action.payload, isFetching: false, error: false })

    case GET_LINKS_FAILURE:
      return Object.assign({}, state, { isFetching: false, error: true })

    case UPDATE_LINK_REQUEST:
      return Object.assign({}, state, { isFetching: true, error: false })

    case UPDATE_LINK_SUCCESS:
      return Object.assign({}, state, { link: action.payload, isFetching: false, error: false })

    case UPDATE_LINK_FAILURE:
      return Object.assign({}, state, { isFetching: false, error: true })

    default:
      return state
  }
}