import { path } from "ramda";
import { put, call } from "redux-saga/effects";
import Store, { history } from "../Store";
import { PathName } from '../Const/PathName';
import { api } from "../Sagas";
// import {
//   stalkLogin, stalkLogout
// } from "../chat/chitchat/chats/redux/stalkBridge/";

import { CLEAR_STORE } from './ManageStoreSaga';

export const AUTH_USER_REQUEST = "AUTH_USER_REQUEST";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAILURE = "AUTH_USER_FAILURE";

export const AUTH_TOKEN_REQUEST = "AUTH_TOKEN_REQUEST";
export const AUTH_TOKEN_SUCCESS = "AUTH_TOKEN_SUCCESS";
export const AUTH_TOKEN_FAILURE = "AUTH_TOKEN_FAILURE";

// export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export function Login(email, password) {
  return new Promise((resolve, reject) => {
    let payload = { email: email, password: password };
    Store.dispatch({ type: AUTH_USER_REQUEST });
    api.authen(payload).then(res => {
      if (res.status === 200) {
        console.log(res.data);
        if (res.data.isStatus) {
          const payload = getAuthSuceesPayload(res);
          Store.dispatch({
            type: AUTH_USER_SUCCESS,
            payload
          });
          afterLoginSuccess();

          // stalkLogin({ username: user.email, _id: user.id.toString() });
        } else {
          Store.dispatch({ type: AUTH_USER_FAILURE });
        }
        console.log(Store.getState());
        resolve(res.data);
      } else {
        Store.dispatch({ type: AUTH_USER_FAILURE });
        reject(res.problem);
      }
    });
  });
}
export function guestLogin() {
  Store.dispatch({ type: AUTH_USER_REQUEST });
  return new Promise((resolve, reject) => {
    api.guestLogin().then(res => {
      if (res.status === 200) {
        if (res.data.isStatus) {
          const payload = getAuthSuceesPayload(res);

          Store.dispatch({
            type: AUTH_USER_SUCCESS,
            payload
          });
          afterLoginSuccess();
          // stalkLogin({ username: user.email, _id: user.id.toString() });
        } else {
          Store.dispatch({ type: AUTH_USER_FAILURE });
        }
        resolve(res.data);
      } else {
        Store.dispatch({ type: AUTH_USER_FAILURE });
        reject(res.problem);
      }
    });
  });
}
export function LoginWithSocial(email, social_type, social_id) {
  return new Promise((resolve, reject) => {
    let payload = { email: email, social_type: social_type, social_id: social_id };
    Store.dispatch({ type: AUTH_USER_REQUEST });
    api.authenWithSocial(payload).then(res => {
      if (res.status === 200) {
        if (res.data.isStatus) {
          const payload = getAuthSuceesPayload(res);

          Store.dispatch({
            type: AUTH_USER_SUCCESS,
            payload
          });
          afterLoginSuccess();

          // stalkLogin({ username: user.email, _id: user.id.toString() });
        } else {
          Store.dispatch({ type: AUTH_USER_FAILURE });
        }
        resolve(res.data);
      } else {
        Store.dispatch({ type: AUTH_USER_FAILURE });
        reject(res.problem);
      }
    });
  });
}

export function* authenUserToken(api, action) {
  console.log("authenUserToken was call with payload: ", action.payload);
  try {
    const res = yield call(api.authenToken, action.payload);
    if (res.status === 200 && res.data.isStatus) {
      console.log(res.data);
      const payload = getAuthSuceesPayload(res);
      yield put({
        type: AUTH_TOKEN_SUCCESS,
        payload
      });
      afterLoginSuccess();

      // stalkLogin({ username: user.email, _id: user.id.toString() });

      console.warn(`----------${AUTH_TOKEN_SUCCESS}----------`);
    } else {
      yield put({ type: CLEAR_STORE });
      // yield put({ type: AUTH_TOKEN_FAILURE });
      if (history.location.pathname !== '/') {
        history.push(`/`);
      }
      console.warn(`----------${AUTH_TOKEN_FAILURE}----------`);
    }
  } catch (e) {
    yield put({ type: CLEAR_STORE });
    // yield put({ type: AUTH_TOKEN_FAILURE });
    if (history.location.pathname !== '/') {
      history.push(`/`);
    }
    console.warn(`----------${AUTH_TOKEN_FAILURE}----------`);
  }
}

const getAuthSuceesPayload = (res) => {
  const user = path(["data", "data", "user"], res);
  const user_profile = path(["data", "data", "user_profile"], res);
  const user_bank_account = path(["data", "data", "user_bank_account"], res);
  const user_setting = path(["data", "data", "user_setting"], res);
  return {
    user, user_profile, user_bank_account,
    user_setting,
  }
}

const afterLoginSuccess = () => {
  if (history.location.pathname === '/') {
    history.push(PathName.FEED);
  }
}

export function Logout() {
  return new Promise((resolve, reject) => {
    // stalkLogout();

    api.logout().then((res) => {
      console.log("logout res: ", res);
      Store.dispatch({ type: CLEAR_STORE });
      if (res.status === 200 && !!res.data && res.data.isStatus) {
        resolve({ success: true });
      }
      else {
        reject({ ...res, ...{ success: false } });
      }
    }).catch((err) => {
      Store.dispatch({ type: CLEAR_STORE });
      reject({ ...err, ...{ success: false } });
    });
  });
}