import { STARTUP } from '../Sagas/StartupSaga'

/* ------------- Initial State ------------- */

export const initialState = {
    startup: false,
}

/* ------------- Reducers ------------- */

export function StartupReducer(state = initialState, action) {
    switch (action.type) {
        case STARTUP:
            return Object.assign({}, state, { startup: true })
        default:
            return state
    }
}