import ReduxPersist from '../Config/ReduxPersist'
import localForage from 'localforage'
import { persistStore } from 'redux-persist'
import { StartupAction } from '../Sagas/StartupSaga'

const updateReducers = (store) => {
    const reducerVersion = ReduxPersist.reducerVersion
    const config = ReduxPersist.storeConfig
    const startup = () => store.dispatch(StartupAction());
    return new Promise((resolve, reject) => {
        // Check to ensure latest reducer version
        localForage.getItem('reducerVersion').then((localVersion) => {
            if (localVersion !== reducerVersion) {
                // console.tron.display({
                //     name: 'PURGE',
                //     value: {
                //         'Old Version:': localVersion,
                //         'New Version:': reducerVersion
                //     },
                //     preview: 'Reducer Version Change Detected',
                //     important: true
                // })
                // Purge store
                // persistStore(store, config, startup).purge()
                persistStore(store, config, () => { startup(); resolve(store); }).purge()
                localForage.setItem('reducerVersion', reducerVersion)
            } else {
                // persistStore(store, config, startup)
                persistStore(store, config, () => { startup(); resolve(store); })
            }
        }).catch(() => {
            // persistStore(store, config, startup)
            persistStore(store, config, () => { startup(); resolve(store); })
            localForage.setItem('reducerVersion', reducerVersion)
        })
    })
}

export default { updateReducers }
